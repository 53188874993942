import initMDB from '../../mdb/mdb/js/autoinit/index.pro';
import Button from '../../mdb/mdb/js/free/button';
import Collapse from '../../mdb/mdb/js/bootstrap/mdb-prefix/collapse';
import Tab from '../../mdb/mdb/js/free/tab';
import Input from '../../mdb/mdb/js/free/input';
import Dropdown from '../../mdb/mdb/js/free/dropdown';
import Modal from '../../mdb/mdb/js/pro/modal';
import Lightbox from '../../mdb/mdb/js/pro/lightbox';

const mdb = { Button, Collapse, Tab, Input, Dropdown, Modal, Lightbox };

initMDB(mdb);

export { Button, Collapse, Tab, Input, Dropdown, Modal, Lightbox, initMDB };
